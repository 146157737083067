import { createTheme } from '@mui/material/styles';
import typography from './typography';
const theme = createTheme({
  palette: {
    
  },
  typography
});


export default theme